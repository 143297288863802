<template>
  <div class="container-fluid px-5 py-5">
    <div class="row px-3 " >
     <div v-for="item of card_img" :key="item.id" :class="'col-'+ item.columnas" class="mt-4">
        <div :style="'background-image: url('+ item.img +'); background-position: center; background-repeat: no-repeat;background-size: cover;background-color:transparent;  position: relative;  height:'+ item.height +'px; border-radius:20px;'" class=" rounded-right "  >
          <div class="row" >
            <div style="position:relative;"  :class=" 'col-' + item.columnaspanel + ' offset-' + item.izquierda  ">
              <div class="px-3 py-3 text-light bg-dark-card " :style="' margin-top:' + (item.posicionpanel - item.alturapanel) + 'px ; height:'+ item.alturapanel +'px ; ' + (item.izquierda > 0 ? 'border-top-left-radius:15px ;' : item.columnaspanel == 12 ? 'border-top-left-radius:0px ; border-top-right-radius:0px ;' :  'border-top-right-radius:15px ;')"  >
                <div :style="(item.columnaspanel == 12 ? ';display:none;' : '')" >
                  <h3 style="white-space: normal !important;">{{item.titulo}}</h3>
                  <p class="mt-2" style="white-space: normal !important;">{{item.descripcion}}</p>
                  <button class="btn btn-primary-2">{{item.textoboton}}</button>
                </div>
                <div class="row" :style="( item.columnaspanel < 12 ? ';display:none;' : '')">
                  <div class="col-7 pr-1">
                    <h3 style="word-wrap: break-word;">{{item.titulo}}</h3>
                    <p class="mt-2" style="word-wrap: break-word;">{{item.descripcion}}</p>
                  </div>
                  <div class="col-5 py-4 text-right">
                    <button class="btn btn-primary-2">{{item.textoboton}}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
     </div>

    </div>
    <!-- <div class="row px-3 mt-4">
      <div class="col-4">
        <div style="background-image: url(../img/Banner.png); background-position: center; background-repeat: no-repeat;background-size: cover;background-color:transparent;  position: relative; width: px; height:400px; border-radius:20px;'" class=" rounded-right "  >
          <div class="row">
            <div class="col-12 " >
              <div class="px-3 py-3 text-light bg-dark-card " style="bottom:70%;"   >
                <div class="row">
                  <div class="col-7 pr-1">
                    <h3 style="word-wrap: break-word;">product.titulo</h3>
                    <p class="mt-2" style="word-wrap: break-word;">Amet minim mollit non deserunt ullamco.</p>
                  </div>
                  <div class="col-5 py-4 text-right">
                    <button class="btn btn-primary-2">Ver detalles</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-8">
        <div style="background-image: url(../img/Banner.png); background-position: center; background-repeat: no-repeat;background-size: cover;background-color:transparent;  position: relative; width: px; height:400px; border-radius:20px;'" class=" rounded-right "  >
          <div class="row">
            <div style="position:relative;" class="col-10  ">
                <div class="px-3 py-3 text-light bg-dark-card " >
                  <h3 style="white-space: normal !important;">product.titulo</h3>
                  <p class="mt-2" style="white-space: normal !important;">product.descripcion</p>
                  <button class="btn btn-primary-2">Ver detalles</button>
                </div>
            </div>
          </div>
        </div>
     </div>
    </div> -->
  </div>
</template>

<script>
export default {
  data(){
    return{
      card_img:[
        {id:1, 
        titulo: "Circuitos", 
        descripcion:"Amet minim mollit non deserunt ullamco.", 
        textoboton: "Ver mas", 
        columnas: 7 , 
        img: '../img/Banner.png',
        posicionpanel: 370, 
        columnaspanel:10 ,
        width: '930',
        height: '370',
        izquierda: 0,
        alturapanel: 170,
        
        },
        
        {id: 2, 
        titulo: "Hoteles", 
        descripcion:"Amet minim mollit non deserunt ullamco.", 
        textoboton: "Ver mas", 
        columnas: 5, 
        img: '../img/Banner_1.png',
        posicionpanel: 'arriba', 
        columnaspanel:12,
        width: '480',
        height: '370',
        alturapanel: 115,
        
        },
        {id: 3, 
        titulo: "Luxury Collection", 
        descripcion:"Amet minim mollit non deserunt ullamco.", 
        textoboton: "Ver mas", 
        columnas: 5, 
        img: '../img/Banner_2.png',
        posicionpanel: 'arriba', 
        columnaspanel:12,
        width: '480',
        height: '370',
        alturapanel: 115,
        posicionpanel: 370, 
        },
        {id:4, 
        titulo: "Promociones y recursos para agentes de viaje ", 
        descripcion:"Accede a promociones, ofertas y precios exclusivos para agentes de viaje.", 
        textoboton: "Ver mas", 
        columnas: 7 , 
        img: '../img/Banner_3.png',
        posicionpanel: 370, 
        columnaspanel:10 ,
        width: '930',
        height: '370',
        izquierda: 2,
        alturapanel: 170,
        
        },
        
        
      ]
    }
  }
}
</script>

<style lang="scss" scoped>

</style>